import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import ShareSocial from "../components/share"
import {Container} from 'react-bootstrap'
import AdvertContact from "../components/advert"
import SEO from "../components/seo"
import Img from "gatsby-image"
import Obfuscate from 'react-obfuscate'

export default function JobTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  let featuredImgFluid = frontmatter.featuredImage.childImageSharp.fluid
  // const imgClassName = `h-100 w-100 serviceImg ${frontmatter.imageclass}`
  return (
    <>
      <Header />
      <SEO title={frontmatter.title} />
      <Container className="mt-2">
        <div className="blog-post-container" style={{display:'flex', justifyContent: 'center'}}>
          <div className="blog-post mt-4" style={{maxWidth: 900}}>
            <h1 style={{textAlign: "center"}}>{frontmatter.title}</h1>
            <Img fluid={featuredImgFluid} className="mb-4 mt-4" style={{height: 150}}/>
            <h5 className="mt-2">Title: {frontmatter.title}</h5>
            <h5 className="mt-2">Level: {frontmatter.level}</h5>
            <h5 className="mt-2">Salary: {frontmatter.salary}</h5>
            <h5 className="mt-2">Location: {frontmatter.location}</h5>
            <h6 className="mt-3">Posted: {frontmatter.date}</h6>
            <h6 className="mt-2 mb-4">Start: {frontmatter.start}</h6>
            <h4>About Opsmorph</h4>
            <div align='justify'>
              {"Opsmorph is a London based data science and cloud solutions research and consultancy start-up formed in 2020. Its mission is to work in co-design with organisations of all sizes to accelerate the development of innovative applications using state-of-the-art cloud technology, web science, machine learning and data visualisation. Opsmorph’s projects span diverse domains including business support tools, FinTech data engineering, sustainable investment data management, and artificial intelligence approaches to cyber security. Opsmorph is motivated to empower all of society to benefit from and contribute to the development of innovative technology and is committed to tackling the biggest of societies challenges together."} 
            </div>
            <div
              className="blog-post-content mt-3"
              dangerouslySetInnerHTML={{ __html: html }}
              align='justify'
            />
            <h4>Equality Statement</h4>
            <div align='justify' className="blog-post-content mb-3">
             {"Opsmorph is proud to be a committed Equal Opportunities employer. Opsmorph’s aim is for our workforce to be truly representative of all sections of society and our customers, and for each employee to feel respected and able to operate at their best. We do not discriminate based on age, disability, gender reassignment, marriage and civil partnership, pregnancy and maternity, race (including colour, nationality, and ethnic or national origin), religion or belief, sex and sexual orientation, or any other legally protected characteristics."} 
            </div>
            <h4>To Apply</h4>
            <div className="blog-post-content mb-4">
              {"Please email your CV with the job title as the subject of the email to:"} 
              <Obfuscate
                email="careers@opsmorph.com"
                className="p-2 add-link"
              />
            </div>
          </div>
        </div>
        <ShareSocial post={markdownRemark}/>
        <AdvertContact />
      </Container>
      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        level
        salary
        start
        location
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        
        # author
      }
    }
  }
`
